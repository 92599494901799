<template>
  <main id="tool-change-manage">
    <section class="tool-change__filter">
      <v-autocomplete
        class="select-machine"
        :items="machine_list"
        v-model="selected_machine" 
        placeholder="기계 선택"
        item-text="name"
        item-value="id"
        hide-details outlined
        :menu-props="{offsetY: true, bottom: true, }"
      >
      </v-autocomplete>

      <v-btn
        class="add-tool-button"
        :disabled='selected_machine ? false: true '
        @click="openAddTool"
      >
        <!-- :disabled="selected_machine == ''" -->
        공구추가 
      </v-btn>
    </section>

    <section class="tool-change__table">
      <!-- ***** 테이블 ***** -->
      <v-data-table
        ref="toolInfoList"
        :headers="header"
        :items="data"
        :disable-pagination="true"
        :footer-props="{ 
          disablePagination: true,
          disableItemsPerPage: true,
        }"
        fixed-header hide-default-footer
        class="tool-change-table"
      >

        <!-- ***** 테이블 ROW ***** -->
        <template v-slot:item="props">
          <tr class="table__row">
            <td 
              class="table__cell"
              :style="{ textAlign: props.item.align, width: props.item.width,}"
            >
              {{ props.item.machine_no }}
            </td>
            <td 
              class="table__cell"
              :style="{ textAlign: props.item.align, width: props.item.width,}"
            >
              {{ props.item.name }}
            </td>
            <td 
              class="table__cell"
              :style="{ textAlign: props.item.align, width: props.item.width,}"
            >
              {{ props.item.tool_name }}
            </td>
            <td 
              class="table__cell"
              :style="{ textAlign: props.item.align, width: props.item.width,}"
            >
              {{ props.item.type }}
            </td>
            <td 
              class="table__cell"
              :style="{ textAlign: props.item.align, width: props.item.width,}"
            >
              {{ props.item.date }}
            </td>
            <td 
              class="table__cell"
              :style="{ textAlign: props.item.align, width: props.item.width,}"
            >
              {{ props.item.count }} / {{ props.item.set_count_val }}
            </td>
            <td 
              class="table__cell percent"
              :style="{ textAlign: props.item.align, width: props.item.width,}"
            >
              <div class="cell__inner">
                {{ props.item.percent }}%
                
                <div class="progress-wrapper">
                  <v-progress-linear 
                    class="percent-progress"
                    :class="{ 'percent--over-100': props.item.percent >= 100, }"
                    :value="props.item.percent >= 100 ? props.item.percent - 100 : props.item.percent"
                    height="8"
                    rounded
                  ></v-progress-linear>
                </div>
                </div>
            </td>
            <td 
              class="table__cell"
              :class="props.item.status"
              :style="{ textAlign: props.item.align, width: props.item.width,}"
            >
              <span v-if="props.item.percent < 90" class="status-label good">상태양호</span>
              <span v-else-if="props.item.percent < 100" class="status-label ready">교체준비</span>
              <span v-else-if="props.item.percent >= 100" class="status-label danger">즉시교체</span>
            </td>
            <td 
              class="table__cell actions"
              :class="props.item.status"
              :style="{ textAlign: props.item.align, width: props.item.width,}"
            >
              <div class="cell__inner">
                <button class="table-action-button change-tool" @click.stop="openModifyTool(props.item)">
                  <v-icon>$changeCheck</v-icon>
                  공구교체
                </button>
                <button class="table-action-button view-history" @click="openHistory(props.item.id)">
                  <v-icon>$documentList</v-icon>
                </button>
                <button class="table-action-button delete-tool" @click.stop="confirmDeleteTool(props.item.id)">
                  삭제
                </button>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </section>

    <!-- ***** DIALOG ***** -->
    <!-- ***** DIALOG 공구 추가 ***** -->
    <v-dialog
      width="450"
      v-model="add_tool.active"
      content-class="dialog-add-item"
      @click:outside="cancelAddTool"
      persistent
    > 
      <v-card class="dialog__inner">
        <section class="dialog__content">
          <header class="content__header">
            <h2 class="header__title">공구 추가</h2>
            <v-btn 
              class="dialog-close-button"
              @click="cancelAddTool"
              depressed fab
            >
              <v-icon size="24">$closeThin</v-icon>
            </v-btn>
          </header>
          <v-form class="dialog__form" ref="toolAddForm" v-model="add_tool.valid">
            <label for="" class="form-label form-label--top">
              <span class="label__text">공구번호</span>
              <v-select
                v-model="add_tool.data.tool_no"
                class="form-select"
                :items="tool_no_list"
                item-value="id"
                item-text="tool_name"
                :rules="valid_rules.tool_no"
                :menu-props="{offsetY: true, bottom: true, }"
                outlined 
                required
              >
                <template v-slot:append>
                  <v-icon>mdi-chevron-down</v-icon>
                </template>
              </v-select>
            </label>
            <label for="" class="form-label form-label--top">
              <span class="label__text">공구명</span>
              <v-text-field
                v-model="add_tool.data.type"
                class="form-field"
                :rules="valid_rules.type"
                :counter="20"
                outlined
                required
              ></v-text-field>
            </label>
            <label for="" class="form-label form-label--top">
              <span class="label__text">공구 교체주기(수율)</span>
              <v-text-field
                type="number"
                v-model="add_tool.data.set_count_val"
                class="form-field"
                :rules="valid_rules.set_count_val"
                outlined
                required
              ></v-text-field>
            </label>
          </v-form>
        </section>
        <v-card-actions class="dialog__actions">
          <v-btn 
          class="action-button button--blue button--full-width add"
          :disabled="!add_tool.valid"
          @click="addTool"
        >추가하기</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ***** DIALOG 공구 교체(수정) ***** -->
    <v-dialog
      width="450"
      v-model="modify_tool.active"
      content-class="dialog-modify-item"
      @click:outside="cancelModifyTool"
      persistent
    > 
      <v-card class="dialog__inner">
        <section class="dialog__content">
          <header class="content__header">
            <h2 class="header__title">공구 교체하기</h2>
            <v-btn 
              class="dialog-close-button"
              @click="cancelModifyTool"
              depressed fab
            >
              <v-icon size="24">$closeThin</v-icon>
            </v-btn>
          </header>
          <article class="content__top-notice">
            <p class="strong">공구교체를 진행하시겠습니까?</p>
            교체시, 공구수명값이 초기화되며,<br>
            기존 기록들은이력보기를 통해 확인 할 수 있습니다.
          </article>
          <v-form class="dialog__form" v-model="modify_tool.valid">
            <label class="form-label form-label--top">
              <span class="label__text">공구명</span>
              <v-text-field
                v-model="modify_tool.data.type"
                class="form-field"
                :rules="valid_rules.type"
                :counter="20"
                outlined
              ></v-text-field>
            </label>
            <label class="form-label form-label--top">
              <span class="label__text">공구 교체주기(수율)</span>
              <v-text-field
                v-model="modify_tool.data.set_count_val"
                type="number"
                :rules="valid_rules.set_count_val"
                class="form-field"
                outlined
              ></v-text-field>
            </label>
          </v-form>
        </section>
        <v-card-actions class="dialog__actions">
          <v-btn class="action-button cancel" @click="cancelModifyTool">나중에</v-btn>
          <v-btn 
            class="action-button button--blue save" 
            :disabled="!modify_tool.valid"
            @click="modifyTool"
          >교체하기</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ***** DIALOG 공구 삭제 ***** -->
    <v-dialog 
      width="450"
      v-model="delete_tool.active"
      content-class="dialog-delete-item"
      @click:outside="cancelDeleteTool"
      persistent
    >
      <v-card class="dialog__inner">
        <section class="dialog__content">
          <header class="content__header">
            <h2 class="header__title">공구 삭제하기</h2>

            <v-btn 
              class="dialog-close-button"
              @click="cancelDeleteTool"
              depressed fab
            >
              <v-icon size="24">$closeThin</v-icon>
            </v-btn>
          </header>
          <article class="content__top-notice">
            <p class="strong text--red">공구를 삭제하시겠습니까?</p>
            삭제시, 공구기록들이 함께 삭제가 되며,<br>
            이후엔 복구할 수 없습니다.
          </article>
        </section>
        <v-card-actions class="dialog__actions">
          <v-btn class="action-button button--red delete" @click="deleteTool">삭제하기</v-btn>
          <v-btn class="action-button button--blue cancel" @click="cancelDeleteTool">아니오</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ***** DIALOG 공구 수정 이력 ***** -->
    <v-dialog
      width="680"
      v-model="modify_history.active"
      content-class="dialog-modify-history"
      @click:outside="closeHistory"
      persistent
    >
      <v-card class="dialog__inner">
        <section class="dailog__content">
          <header class="content__header">
            <h2 class="header__title">공구 교체 이력</h2>

            <v-btn 
              class="dialog-close-button"
              @click="closeHistory"
              depressed fab
            >
              <v-icon size="24">$closeThin</v-icon>
            </v-btn>
          </header>
          <v-data-table 
            :items="modify_history.data"
            :headers="modify_history.header"
            :page.sync="modify_history.page"
            @page-count="modify_history.page_count = $event"
            class="modify-history-table"
            hide-default-footer
          >
          <template #item.count ="{item}">{{item.count}}/{{item.set_count_val}}</template>
          <template #item.percent ="{item}">{{Math.floor((item.count/item.set_count_val)*100)}}%</template>
          </v-data-table>
          <div class="modify-history__pagination">
            <v-pagination 
              class="pagination"
              v-model="modify_history.page"
              :length="modify_history.page_count"
            ></v-pagination>
          </div>
        </section>
      </v-card>
    </v-dialog>

    <!-- ***** DIALOG 공구 교체 준비 경고 ***** -->
    <v-dialog 
      content-class="dialog-change-ready rounded-0 dialog-alert--yellow"
      v-model="change_ready.active"
      @click:outside="change_ready.active = false"
      persistent
    >
      <v-card class="dialog__inner">
        <v-icon class="alert-icon" size="127">$triAlert</v-icon>
        <p class="alert__title">공구교체를 준비해주세요!</p>
      </v-card>
    </v-dialog>
      
    <!-- ***** DIALOG 공구 교체 경고 ***** -->
    <v-dialog 
      content-class="dialog-reach-count rounded-0 dialog-alert--red"
      v-model="reach_count.active"
      @click:outside="reach_count.active = false"
      persistent
    >
      <v-card class="dialog__inner">
        <v-icon class="alert-icon" size="127">$triAlert</v-icon>
        <p class="alert__title">공구를 즉시 교체해 주세요!!</p>
      </v-card>
    </v-dialog>

  </main>
</template>

<script>
import moment from "moment"
export default {
  components: {},
	name: 'tool',
	data( ) {
    return {
      process_list: [], // 테이블에 보여지는 데이터
      machine_list: [], // 기계 목록
      tool_no_list: [], // 공구번호 목록
      selected_machine: null, // 선택된 공구
      type_list : [ // 공구 유형 리스트
        "외경 황삭", "외경 중삭", "외경 정삭",
        "내경 황삭", "내경 중삭", "내경 정삭",
        "날개 황삭", "날개 정삭", 
        "볼자리 황삭", "볼자리 정삭",
        "홈",
        "후가공",
        "드릴",
      ],
      header: [ // 테이블 헤더 정의
        { text: "호기", value: "machine_no", sortable: false, align: "left", width: "80px" },
        { text: "기계명", value: "name", sortable: false, align: "left", width: "auto" },
        { text: "공구번호", value: "tool_name", sortable: false, align: "left", width: "100px" },
        { text: "공구유형", value: "type", sortable: false, align: "left", width: "120px" },
        { text: "공구교체일", value: "date", sortable: false, align: "left", width: "160px" },
        { text: "작업횟수", value: "count", sortable: false, align: "left", width: "160px" },
        { text: "공구사용률", value: "percent", sortable: false, align: "left", width: "190px" },
        { text: "상태", value: "status", sortable: false, align: "left", width: "140px" },
        { text: "활동", value: "actions", sortable: false, align: "left", width: "230px" },
      ],
      data: [ // 테이블 데이터
       
      ],
      valid_rules: {
        tool_no: [
          v => !!v || "공구번호를 선택해주세요!"
        ],
        type: [
          v => !!v || "공구명 입력이 필요합니다!"
        ],
        set_count_val: [
          v => v >= 0 && v !== '' && v !== null || "공구교체주기를 입력해주세요!!"
        ],
      },
      add_tool: { // 공구 추가 알림창 관련
        active: false,
        valid: true,
        data: {
          tool_no: null,
          type: null,
          set_count_val: 0,
        },
      },
      modify_tool: { // 공구 수정 알림창 관련
        active: false,
        valid: true,
        data: {
          type: null,
          set_count_val: 0,
        },
      },
      delete_tool: { // 공구 삭제 알림창 관련
        active: false,
        target: "",
      },
      modify_history: { // 교체 이력 알림창 관련
        active: false,
        page: 1,
        page_count: 0,
        header: [ // 공구 교체 이력 테이블 헤더 정의
          { text: "No.", value: "index", sortable: false, align: "left", width: "80px" },
          { text: "공구교체일", value: "date", sortable: false, align: "left", width: "auto" },
          { text: "작업횟수", value: "count", sortable: false, align: "left", width: "auto" },
          { text: "공구사용율", value: "percent", sortable: false, align: "left", width: "auto" },
        ],
        data: [ // 공구 교체 이력 테이블 데이터
        ]
      },
      change_ready: { // 교체 준비 알림창 관련
        active: false,
      },
      reach_count: { // 즉시 교체 알림창 관련
        active: false,
        over_tool: "",
      },
      lot: Number,
      row: Number,
      mid: Number,
      array: [],
      seq: 0,
      count: Number,
      status: String,
      toolmap:{
        correction_xval:0,
        base_correction_xval:0,
        correction_zval:0,
        base_correction_zval:0
      },
      index:Number,
      selectedRow:null,
    }
  },
  mounted() {
    this.$cnc.state = 'Tool';
    this.selectMid();
    this.selectToolList();
    this.$connect(this.$store.state.websocket_url+this.$store.state.sender+'?ent='+this.$store.state.enterprise+'&view=noti');
    this.$options.sockets.onmessage = this.$cnc.listenWebSocketResponse();
    this.$cnc.toolCount((mkey)=>{
      this.countUp(mkey);
    });
    // this.mid = this.$store.state.selectedCnc.mkey;
    // this.$cnc.setCncList(this.process_list);
    // this.selectLot();
    // this.loadCNC();
   
    // this.$cnc.state=2;
    // this.$cnc.start(this.mid);
   
    // this.$cnc.toolCount((val)=>{
    //   this.dataInsert();
    // });
    // this.$cnc.toolReset((index,xval,zval,set_count_val)=>{
    //   if(this.index){
    //     this.data[index].count=0;
    //     this.data[index].correction_xval = xval;
    //     this.data[index].correction_zval = zval;
    //     this.data[index].set_count_val = set_count_val;
    //   }
    // });
    // this.$cnc.requestHandler((resp)=>{
    //   this.requestHandler(resp);
    // });
  },
  watch:{ 
    selected_machine:function(v){
      this.getData(v);
    },
    data:{
      deep:true,
      handler(v){
        v.map(t=>{
          t.percent =Math.floor((t.count/t.set_count_val)*100); 
          return t;
        })
      }
    }
    
  },
  destroyed:function(){
		this.$disconnect();
    this.$cnc.state = null;
  },
  methods: {
     // ********** 공구 select 정보 가져오기 ex) T01,T02 ********** //
    selectToolList(){
      this.$http.APS.get('/tool/list').then(r=>{
        this.tool_no_list = r.data;
      })
    },
     // ********** 장비 select가져오기 ********** //
    selectMid(){
      this.$http.APS.get('/idle/key').then(r=>{
        this.machine_list=r.data;
      })
    },
     // ********** 메인 화면 데이터 가져오기 ********** //
    getData(mkey){
      this.$http.APS.get('/tool/data/'+mkey).then(r=>{
        this.data = r.data;
      })
    },
     // ********** 공구추가 ********** //
    async addTool() {
      this.$refs.toolAddForm.validate();
      this.add_tool.data.mkey = this.selected_machine;
      await this.$http.APS.post('/tool',this.add_tool.data);
      await this.cancelAddTool();
      await this.getData(this.selected_machine);
      
    },
     // ********** 카운트 + 1 ********** //
    countUp(mkey){
      console.log(mkey);
      this.$http.APS.patch('/tool/countUp',{mkey:mkey});
      if(mkey == this.selected_machine){
        console.log("upup");
        this.data.map(t=>{
          t.count+=1;
          return t;
        })
      }
    },
     // ********** 공구 삭제 팝업 ********** //
    confirmDeleteTool(id) {
      let tool = this.delete_tool;
      tool.active = true;
      tool.target = id;
    },
     // ********** 공구 삭제 ********** //
    async deleteTool() {
      const id = this.delete_tool.target;
      await this.$http.APS.delete('/tool/'+id);
      await this.getData(this.selected_machine);
      await this.cancelDeleteTool();
      this.$http.EDGE.post("/api/edge/broadcast", {enterprise: "YHS", data: {type: 'reload', mkey: this.selected_machine}});
    },
     // ********** 공구교체이력 ********** //
    openHistory(id) {
      const history = this.modify_history;
      history.active = true;
      this.$http.APS.get('/tool/history/'+id).then(r=>{
        r.data.map((t,i)=>{t.index = i+1; return t});
        history.data = r.data;
      })
    },
    closeHistory() {
      const history = this.modify_history;

      history.active = false;
    },
    // ********** 공구 교체 경고 알림창 ********** //
    activeChangeAlert(type) {
      this[type].active = true;
      setTimeout(() => this[type].active = false, (10 * 1000));
    },



    loadCNC() {
      this.$store.state.overlay=true;
      this.$http.APIS.get('/api/cloud/active_selectMachine/'+this.mid).then(r =>{
        this.selectedMachine =r.data.data[0] 
      });
      this.$store.state.overlay = false;
    },
    Trans(val,base) {
      if(val == undefined){
        return base;
      }else{
        return val;
      }
    },
    async dataInsert() {
      await this.$http.DT.get('/tool/'+this.mid).then(r=>{
        this.seq = r.data[0].seq;
      });
      this.status = 'dataInsert'
      this.callfunc(this.selectedMachine,"rdtofsr");
    },
    requestHandler:async function(resp) {
      if("CALL_FUNC_RESULT" == resp.response){
        if(resp.request.func == "rdtofsr"){
          var line = resp.data.split("^");
          var toolval=line[1].split("#");
          this.toolmap.correction_xval=(parseInt(toolval[1])/1000).toFixed(3);
          this.toolmap.correction_zval=(parseInt(toolval[3])/1000).toFixed(3);
          if(this.status == 'dataInsert'){
            for(let i=0; i<this.data.length; i++){
              let toolno= this.data[i].tool_no.slice(-2).substring(0,1) == 0 ? this.data[i].tool_no.slice(-1): this.data[i].tool_no.slice(-2);
              toolval = line[toolno].split("#");
              this.data[i].count += 1;
              this.data[i].hist_seq = this.seq;
              this.data[i].correction_xval = (parseInt(toolval[1])/1000).toFixed(3);
              this.data[i].correction_zval = (parseInt(toolval[3])/1000).toFixed(3);
            }
            await this.$http.DT.post('/tool/data',this.data)
            this.checkCount();
          }else if(this.status=='Baseinsert'){
            this.insert_data.base_correction_xval = (parseInt(toolval[1])/1000).toFixed(3);
            this.insert_data.base_correction_zval = (parseInt(toolval[3])/1000).toFixed(3);
            this.insertTool();
          }else if(this.status == 'reset'){
            let datamap = [];
            datamap[0] = this.data[this.index];
            // this.data[this.index].count=0;
            this.data[this.index].correction_xval = this.toolmap.correction_xval;
            this.data[this.index].correction_zval = this.toolmap.correction_zval;
            let reset_data = {};
            reset_data.index = this.index;
            reset_data.xval = this.toolmap.correction_xval;
            reset_data.zval = this.toolmap.correction_zval;
            reset_data.set_count_val = this.count;
            this.$http.DT.post('/tool/data',datamap);
            this.$http.EDGE.post('/api/edge/broadcast',{enterprise:'YHS',data:{type:'toolChange',reset_data}});
          }
        }
          this.status=String;
      }
    },
    callfunc(item,func,arg) {
      this.$store.state.overlay = true;
      item.func = func;
      item.no = arg;
      this.$http.EDGE.post('/api/edge/callfunc',item);
    },
    checkCount(){
      let tool = this.reach_count.over_tool;

      for(let i = 0; i < this.data.length; i++){

        if(this.data[i].count >= this.data[i].set_count_val){
          let toolno = 'T'+this.data[i].tool_no.slice(-2);
          tool = tool.indexOf(toolno) == -1 ? tool.length == 0 ? toolno : tool.concat(',', toolno) : tool;
        }
      }
      if(tool.length != 0){
        this.reach_count.open = true;
      }
    },
    async toolData(){
      await this.$http.DT.get('/tool/'+this.$store.state.enterprise_id+'/'+this.mid+'/'+this.lot).then(r=>{
        r.data.forEach((item) => {
          if(item.correction_xval){
            item.correction_xval = item.correction_xval.toFixed(3);
          }
          if(item.correction_zval){
            item.correction_zval = item.correction_zval.toFixed(3);
          }
        });
        this.data = r.data;
      })
      this.checkCount();
    },
    async insertTool(){
      this.insert_data.lot_no = this.lot;
      this.insert_data.ent = this.$store.state.enterprise_id;
      this.insert_data.mid = this.mid;
      await this.$http.DT.post("/tool", this.insert_data).then(r => {
        this.resetTemplate;
      })
      this.$http.EDGE.post("/api/edge/broadcast", {enterprise:"YHS", data:{type: "reload", mkey:this.mid}});
    },
    selectLot(){
      this.$http.DT.get("/tool/" + this.$store.state.enterprise + "/" + this.mid).then(r => {
        this.lot = r.data[0].lot;
      });
    },
    // ********** 공구 값 템플릿으로 리셋 ********** //
    resetTemplate() {
      this.insert_data = this.template;
    },
    // ********** 공구 추가 ********** //
    openAddTool() {
      const tool = this.add_tool;

      tool.active = true;
    },
    
    cancelAddTool() {
      this.add_tool.active = false;
    },
    // ********** 공구 수정 ********** //
    openModifyTool(data) {
      const tool = this.modify_tool;
      tool.data = data;
      tool.data.pre = data.set_count_val;
      tool.active = true;
    },
    async modifyTool() {
      await this.$http.APS.patch('/tool/',this.modify_tool.data);
      await this.cancelModifyTool();
      await this.getData(this.selected_machine);
     
      // await this.$http.DT.patch("/tool/", this.insert_data).then(r => {
      //   this.resetTemplate();
      // });
      // this.$http.EDGE.post("/api/edge/broadcast", {enterprise:"YHS",data:{type: "reload", mkey: this.mid}});
    },
    cancelModifyTool() {
      this.modify_tool.active = false;
      this.selectedRow = null;
      this.resetTemplate();
    },
   
    cancelDeleteTool() {
      this.delete_tool.active = false;
    },
    // ********** 공구 리셋 (초기값, 보정값, 가공갯수) ********** //
    // async resetItem(item) {
    //   let index = this.data.indexOf(item);
    //   this.status='reset';
    //   this.index = index;
    //   this.count = this.data[index].count;
    //   this.data[index].set_count_val = this.data[index].count;
    //   await this.$http.DT.patch('/tool/re/',{set_count_val:this.data[index].count,id:this.data[index].id})
    //   this.callfunc(this.selectedMachine,"rdtofsr",this.data[index].tool_no.slice(-2));
    //   this.data[index].count = 0;
    //   await this.resetTemplate();
    // },
    // closeReachCount() {
    //   this.reach_count.open = false;
    //   this.reach_count.over_tool = '';
    // },
    // rowSelect(item) {
    //   this.selectedRow = item.id;
    //   this.add_row.mode = true;
    //   this.insert_data = Object.assign({}, item);
    // },
   
  },
}
</script>

<style lang="scss" scoped>

  #tool-change-manage {
    padding: 40px 24px;
  }

  .tool-change__filter {
    @include flex(false, row, nowrap, space-between, center);

    margin-bottom: 24px;
  }
  ::v-deep .select-machine {
    @include select--round-outline;
  }
  ::v-deep .add-tool-button {
    width: 180px;
    height: 50px !important;
    border-radius: 5px;
    color: white;
    box-shadow: 0 2px 6px 0 rgba(black, 0.3);
    transition: all 0.2s;
    background-color: $main-blue !important;

    &:disabled {
      color: black;
      transition: all 0.2s;
      box-shadow: unset;
      background-color: $skyblue-lighter1;
    }
    &:active {
      box-shadow: unset;
    }

    .v-btn__content {
      font-size: 18px;
      font-weight: $semi-bold;
    }

  }


  ::v-deep .tool-change__table {
    @extend .common-table--light-gray;
    @include last-border(#e0e0e0);

    .percent {

      .cell__inner {
        @include flex(false, row, nowrap, space-between, center);
      }
      .progress-wrapper {
        width: 100px;
      }
    }
    .percent-progress {
      @include linear-progress-color;

      &.percent--over-100 {
        @include linear-progress-color($main-red, $main-blue)
      }
    }

    .status-label {
      display: inline-block;
      padding: 0 18px;
      border-radius: 100px;
      color: white;
      line-height: 27px;
      font-weight: $semi-bold;

      &.good {
        background-color: $main-green;
      }
      &.ready {
        background-color: $traffic-yellow;
      }
      &.danger {
        background-color: $main-red;
      }
    }

    .actions {

      .cell__inner {
        @include flex(false, row, nowrap, space-between, center);
      }
    }
     .table-action-button {
      display: inline-block;

      &.change-tool {
        @include flex(false, row, nowrap, center, center);
        @include button-outline-color(#4C4E64DE, true, "rounded");

        padding: 0 10px 0 6px;

        .v-icon {
          margin-right: 4px;
        }
      }
      &.view-history {
        @include button-outline-color(#4C4E64DE);

        padding: 0 2px;

        .v-icon {
          margin-top: -2px;
        }
      }
      &.delete-tool {
        @include button-outline-color($main-red, $hover: true);
      }
    }
  }

  // ********** DIALOG ********** //
  ::v-deep .dialog-add-item {
    @extend .dialog--default;
  }
  ::v-deep .dialog-modify-item {
    @extend .dialog--default;
  }
  ::v-deep .dialog-delete-item {
    @extend .dialog--default;

    .content__top-notice {
      border-bottom: 0;
      margin-bottom: 40px;
    }
  }
  ::v-deep .dialog-modify-history {
    @extend .dialog--default;
  }
  ::v-deep .modify-history-table {
    @extend .common-table--light-gray;
    @include last-border(#e0e0e0);

    padding: 27px 42px 24px;
  }
  ::v-deep .modify-history__pagination {
    @extend .common-table-pagination--light-gray;

    padding-bottom: 27px;
  }
  ::v-deep .dialog-change-ready {
    @extend .dialog-alert;
  }
  ::v-deep .dialog-reach-count {
    @extend .dialog-alert;
  }
  

</style>